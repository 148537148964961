import YoutubeVideo from "@/components/Cliente/YoutubeVideo/YoutubeVideo.vue";
import Banner from "@/components/Cliente/Banner/Banner.vue";

export default {
	components: {
		YoutubeVideo,
		Banner
	},
	data() {
		return {
			arBreadcrumbs: [{
					text: "Início",
					href: "/"
				},
				{
					text: "Vídeos"
				}
			],
			arCategorias: [],
			arVideos: [],
			blLoading: true,
			idCategoria: 0,
			rolagemMenu: false
		}
	},
	created() {
		this.listar();
		window.addEventListener("scroll", this.esconderMenuLateral);
	},
	methods: {
		listar() {
			this.$root.$api.get('video/listar').then(
				(response) => {
					this.arVideos = response.retorno.videos;
					this.arCategorias = response.retorno.categorias;
					this.blLoading = false;
				}
			)
		},
		esconderMenuLateral() {
			this.rolagemMenu = window.scrollY > 100 ? "block" : "none";
		}
	},
	computed: {
		videos() {
			if (this.idCategoria == 0) {
				return this.arVideos.filter(
					obVideo => {
						return obVideo.id_categoria != 4
					}
				);
			}

			return this.arVideos.filter(obVideo => {
				return obVideo.id_categoria == this.idCategoria
			})
		},
		videoOld() {
			if (this.idCategoria == 0) {
				return this.arVideos.filter(
					obVideo => {
						return obVideo.id_categoria == 4
					}
				);
			}

			return [];
		}
	},
}